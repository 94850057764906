@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/world/world-custom.scss';
@import '../../../../styles/world/world-typography.scss';

.root {
  margin: 0;
  width: 100%;
  position: absolute;
  transform: translateY(-72%);
  text-align: center;

  top: 33%;
  @include media-breakpoint-up(md) {
    top: 41%;
  }

  &.reducedHeight {
    position: relative;
    transform: none;
    top: 0;

    @include media-breakpoint-up(md) {
      top: 0;
    }
    
    .eyebrow {
      margin: 0 20px;
    }
    
    .header {
      margin: 6px 20px 24px;

      @include media-breakpoint-up(md) {
        top: 0;
        margin: 10px 20px 0px;
      }
    }

    .scarcityIcon {
      @include media-breakpoint-up(md) {
        margin-top: 16px;
      }
    }

    .displayLogo {
      @include media-breakpoint-up(md) {
        top: auto;
      }
      @include media-breakpoint-up(lg) {
        top: auto;
      }
    }
  }
}

.displayLogo {
  height: 150px;
  width: 200px;
  top: 10px;
  position: relative;
  margin: auto;

  @include media-breakpoint-up(md) {
    height: 200px;
    width: 250px;
    top: -45px;
  }
  @include media-breakpoint-up(lg) {
    height: 300px;
    width: 400px;
    top: -60px;
  }
}

.eyebrow {
  font-family: $sofiapro;
  font-weight: 400;
  color: $white;
  text-transform: uppercase;

  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 1.33px;
  @include media-breakpoint-up(md) {
    font-size: 18px;
    line-height: 1.2;
    letter-spacing: 1px;
  }
}

.header {
  text-transform: uppercase;
  color: $white;

  font-size: 48px;
  line-height: 1;
  margin: 20px 5px 5px 5px;
  letter-spacing: 1px;
  @include media-breakpoint-up(md) {
    font-size: 76px;
    line-height: 0.95;
    margin: 15px 5px 5px 5px;
    letter-spacing: 1px;
  }
}

.scarcityIcon {
  margin: auto;
  position: relative;

  width: 160px;
  height: 18px;
  margin-top: 20px;
  @include media-breakpoint-up(md) {
    width: 264px;
    height: 64px;
    margin-top: 25px;
  }
}
