@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/world/world-custom.scss';
@import '../../styles/world/world-typography.scss';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--color-b);

  .heroBackground {
    min-height: 800px;
    height: calc(150vw / 2);
    max-height: 1050px;
    position: relative;
    display: flex;
    flex-direction: column-reverse;

    video {
      position: absolute;
    }
  }

  > section {
    margin-top: 80px;
    overflow: hidden;
    max-width: 100vw;
    padding-left: 30px;
    box-sizing: border-box;

    &:first-child {
      margin-top: 0;
    }
  }

  .secondaryCarousel {
    margin-bottom: 100px;
  }
}

.collectionVideoWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  padding: 0;
  box-sizing: border-box;
  margin: 50px auto;

  h3 {
    font-family: $harrybeastregular;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    color: $white;
    font-size: 24px;
    margin: 50px 10px;

    @include media-breakpoint-up(sm) {
      font-weight: 400;
      font-size: 38px;
    }
  }

  @include media-breakpoint-up(lg) {
    max-width: 1280px;
  }
}

.heroSliderWrapper {
  width: 100%;
  overflow: hidden;
}

.referenceWrapper {
  @include media-breakpoint-down(md) {
    margin-top: 120px;
  }
}

.symbol {
  position: relative;
  z-index: 2;
  margin-bottom: 200px;
}
